import { defineStore } from 'pinia';
import type { User } from '~/shared/types';

export const useUser = defineStore('user', () => {
  const user = ref<User | undefined>(undefined);
  const init = ref<boolean>(false);

  const getUser = async (): Promise<User | undefined> => {
    if (!init.value) {
      user.value = await getUserData();
      init.value = true;
    }
    return user.value;
  };

  const reset = () => {
    user.value = undefined;
    init.value = false;
  };

  return {
    user,
    init,
    getUser,
    reset,
  };
});
